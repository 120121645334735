import React from 'react';
import data from '../data/home';

function Home(props) {
  return (
    <div>
      <section id="hero" className="d-flex align-items-center">
        <div className="container">
          <h1 className="h1-title">{props.company.welcomeTitle}</h1>
          <h2 className="h2-title" >{props.company.welcomeNote}</h2>
          <a href="/about" className="btn-get-started scrollto">Get Started</a>
        </div>
      </section>
      <section id="why-us" className="why-us">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 d-flex align-items-stretch">
              <div className="content">
                <h3>Why Choose LEF?</h3>
                <p className="text-small">
                At LEF, we take great pride in the reputation we have built and maintained. For every new project our team members will walk you through the process, listen to your requirements
                and budget, then create a plan to suit your needs. Our goal is to foster a long-term business with our valued customers.   
                <br/>
                <br/>
                <span className="quote pt-3">'Perfection is not attainable. But if we chase perfection, we can catch excellence' – Vince Lombardi</span>
                </p>
               
                <div className="text-center">
                  <a href="/product-piping" className="more-btn">Learn More <i className="bx bx-chevron-right"></i></a>
                </div>
              </div>
            </div>
            <div className="col-lg-8 d-flex align-items-stretch">
              <div className="icon-boxes d-flex flex-column">
                <div className="row">
                  <div className="col-xl-4 d-flex align-items-stretch">
                    <div className="icon-box mt-4 mt-xl-0">
                      <i className="bx bx-user-check"></i>
                      <h4>{data.whyTitle1}</h4>
                      <p className="why">{data.whyNote1}</p>
                    </div>
                  </div>
                  <div className="col-xl-4 d-flex align-items-stretch">
                    <div className="icon-box mt-4 mt-xl-0">
                      <i className="bx bx-cube-alt"></i>
                      <h4>{data.whyTitle2}</h4>
                      <p className="why">{data.whyNote2}</p>
                    </div>
                  </div>
                  <div className="col-xl-4 d-flex align-items-stretch">
                    <div className="icon-box mt-4 mt-xl-0">
                      <i className="bx bx-phone-call"></i>
                      <h4>{data.whyTitle3}</h4>
                      <p className="why">{data.whyNote3}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
