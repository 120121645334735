import React, {useState} from 'react';
import ReactBnbGallery from 'react-bnb-gallery';
import 'react-bnb-gallery/dist/style.css'

function Facilities() {

  const photos = [
    {
      "photo": "/assets/img/lef/factory1.jpg",
      "caption": "",
      "subcaption": "",
    },
    {
      "photo": "/assets/img/lef/factory2.jpg",
      "caption": "",
      "subcaption": "",
    },
    {
      "photo": "/assets/img/lef/factory3.jpg",
      "caption": "",
      "subcaption": "",
    },
    {
      "photo": "/assets/img/lef/factory4.jpg",
      "caption": "",
      "subcaption": "",
    },
    {
      "photo": "/assets/img/lef/factory5.jpg",
      "caption": "",
      "subcaption": "",
    },
    {
      "photo": "/assets/img/lef/factory6.jpg",
      "caption": "",
      "subcaption": "",
    },
    {
      "photo": "/assets/img/lef/factory7.jpg",
      "caption": "",
      "subcaption": "",
    }
  ];
  const [gallery, setGallery] = useState({ open: false, index: 0});
  const toggleGallery = (index) => {
    setGallery({
      open: !gallery.open,
      index
    });
  };

  return (
    <div>
      <section id="about" className="about pt-100">
        <div className="container pt-5">
          <div className="row">
            <div className="justify-content-center icon-boxes">
              <h4>Factory</h4>
              <div className="p-2">
                <table>
                  <tr>
                    <td style={{minWidth: 120}}><b>Total Area</b></td>
                    <td>:</td>
                    <td> 2,636 m.sq / 28,370 sq.ft</td>
                  </tr>
                  <tr>
                    <td><b>Covered Area</b></td>
                    <td>:</td>
                    <td> 2,136 m.sq / 22,990 sq.ft</td>
                  </tr>
                  <tr>
                    <td><b>Open Area</b></td>
                    <td>:</td>
                    <td> 500 m.sq / 5,400 sq.ft</td>
                  </tr>
                  <tr>
                    <td><b>Office Area</b></td>
                    <td>:</td>
                    <td> 190 m.sq / 2,045 sq.ft</td>
                  </tr>
                  <tr>
                    <td><b>Hook Height</b></td>
                    <td>:</td>
                    <td> 10 meter</td>
                  </tr>
                </table>
              </div>
            
              <ReactBnbGallery activePhotoIndex={gallery.index} show={gallery.open} photos={photos} onClose={toggleGallery.bind(null, 0)} />
              <p className="text-justify">
                <img className="thumbnail-image width350" src="assets/img/lef/factory1.jpg" alt="image"  onClick={toggleGallery.bind(null, 0)} />
                <img className="thumbnail-image width350" src="assets/img/lef/factory2.jpg" alt="image"  onClick={toggleGallery.bind(null, 1)} />
                <img className="thumbnail-image width350" src="assets/img/lef/factory3.jpg" alt="image"  onClick={toggleGallery.bind(null, 2)} />
                <img className="thumbnail-image width350" src="assets/img/lef/factory4.jpg" alt="image"  onClick={toggleGallery.bind(null, 3)} />
                <img className="thumbnail-image width350" src="assets/img/lef/factory5.jpg" alt="image"  onClick={toggleGallery.bind(null, 4)} />
                <img className="thumbnail-image width350" src="assets/img/lef/factory6.jpg" alt="image"  onClick={toggleGallery.bind(null, 5)} />
                <img className="thumbnail-image width350" src="assets/img/lef/factory7.jpg" alt="image"  onClick={toggleGallery.bind(null, 6)} />
              </p>
            </div>
            
          </div>
          
          <div className="row pt-5">
            <div className="justify-content-center icon-boxes">
              <h4>List of Equipments</h4>
              <table className="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">DESCRIPTION</th>
                    <th scope="col">QTY.</th>
                  </tr>
                </thead>
                <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td>WELDING MACHINE - LINCOLN (500 AMPS)</td>
                  <td>2</td>
                </tr>
                <tr className="background-color: grey">
                  <th scope="row">2</th>
                  <td>WELDING MACHINE - MILLER (300 AMPS)</td>
                  <td>1</td>
                </tr>
                <tr>
                  <th scope="row">3</th>
                  <td>WELDINGMACHINE- TIG (200 AMPS)</td>
                  <td>6</td>
                </tr>
                <tr>
                  <th scope="row">4</th>
                  <td>WELDING MACHINE-SMAW (300 AMPS)</td>
                  <td>2</td>
                </tr>
                <tr>
                  <th scope="row">5</th>
                  <td>COLD CUTTER (UNTIL 10 INCH PIPE)</td>
                  <td>1</td>
                </tr>
                <tr>
                  <th scope="row">6</th>
                  <td>ELECTRODE BAKING OVEN (300 DEG)</td>
                  <td>1</td>
                </tr>
                <tr>
                  <th scope="row">7</th>
                  <td>FORKLIFT-3 TON TOYOTA</td>
                  <td>1</td>
                </tr>
                <tr>
                  <th scope="row">8</th>
                  <td>WELDING MACHINE-FCAW (500AMPS)</td>
                  <td>2</td>
                </tr>
                <tr>
                  <th scope="row">9</th>
                  <td>ELECTRODE QUIVERS (5KG)</td>
                  <td>10</td>
                </tr>
                <tr>
                  <th scope="row">10</th>
                  <td>FIBER CUTTER (14")</td>
                  <td>3</td>
                </tr>
                <tr>
                  <th scope="row">11</th>
                  <td>MAGNETIC DRILL</td>
                  <td>1</td>
                </tr>
                <tr>
                  <th scope="row">12</th>
                  <td>GOUGING MACHINE - LINCOLN (500AMPS)</td>
                  <td>1</td>
                </tr>
                <tr>
                  <th scope="row">13</th>
                  <td>AIR COMPRESSOR (2 HP)</td>
                  <td>1</td>
                </tr>
                <tr>
                  <th scope="row">14</th>
                  <td>PIPE OXY AUTO CUTTER</td>
                  <td>1</td>
                </tr>
                <tr>
                  <th scope="row">15</th>
                  <td>PLASMA CUTTER (8MM THICKNESS)</td>
                  <td>1</td>
                </tr>
                <tr>
                  <th scope="row">16</th>
                  <td>DRILL MACHINE</td>
                  <td>1</td>
                </tr>
                <tr>
                  <th scope="row">17</th>
                  <td>4" GRINDERS</td>
                  <td>20</td>
                </tr>
                <tr>
                  <th scope="row">18</th>
                  <td>PENCIL GRINDER</td>
                  <td>5</td>
                </tr>
                <tr>
                  <th scope="row">19</th>
                  <td>7" GRINDER</td>
                  <td>3</td>
                </tr>
                <tr>
                  <th scope="row">20</th>
                  <td>MILLING MACHINE</td>
                  <td>1</td>
                </tr>
                <tr>
                  <th scope="row">21</th>
                  <td>WELDING MACHINE - LINCOLN (400 AMPS)</td>
                  <td>2</td>
                </tr>
                <tr>
                  <th scope="row">22</th>
                  <td>5.0 TON OVERHEAD CRANE</td>
                  <td>1</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Facilities;
