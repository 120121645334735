import React from 'react';

function Contact() {
  return (
    <section id="contact" className="contact pt-100">
      <div className="container">

        <div className="section-title p-5">

          <table className="table  text-start">
            <tbody>
            <tr>
              <th className="border-top-0" scope="row">NAME OF COMPANY</th>
              <td className="border-top-0 text-left">LAKSAMANA ENGINEERING AND FABRICATION SDN. BHD. (1324524-D)</td>
            </tr>
            <tr>
              <th scope="row">BUSINESS ADDRESS</th>
              <td className="text-left">Lot 19790-A, Jalan KPK 1/1,<br/>
                Kawasan Perindustrian Kundang Jaya,<br/>
                48020 Rawang, Selangor. <br/>
              </td>
            </tr>
            <tr>
              <th scope="row">TEL. NO.</th>
              <td className="text-left">+603-8727 0304</td>
            </tr>
            <tr>
              <th scope="row">EMAIL</th>
              <td className="text-left">sales@laksamana-engineering.com</td>
            </tr>
            <tr>
              <th scope="row">CONTACT PERSON</th>
              <td className="text-left">MR. RAVI CHANAN</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div>
        <iframe  className="contact" style={{border:0,  width: '100%', height: '350px' }}
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3983.241799225508!2d101.51972591470499!3d3.2901738976047192!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc5cb575f3829b%3A0xa4e1928464c78e27!2sJalan%20Kpk%201%2F1%2C%20Kawasan%20Perindustrian%20Kundang%2C%2048020%20Rawang%2C%20Selangor!5e0!3m2!1sen!2smy!4v1668424443910!5m2!1sen!2smy&t=&z=13&ie=UTF8&iwloc=&output=embed"
                frameBorder="0" allowFullScreen></iframe>
      </div>
    </section>
  );
}

export default Contact;
