import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
function Header(props) {

  // const [scrolled, setScrolled] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [dropdown, setDropdown] = useState(0);
  const toggleMobile = () => {
    setMobile(!mobile);
  };

  const toggleDropdown = (menu) => {
    if (menu == dropdown) setDropdown(0);
    else {
      setDropdown (menu);
    }
  };


  // const scroll = () => {
  //   if (window.scrollY > 100) {
  //     setScrolled(true)
  //   } else {
  //     setScrolled(false)
  //   }
  // };

  const getPathId = () => {
    const path = window.location.pathname;
    if (path === '/') {
      return 1;
    } else if (path.indexOf('/about') != -1){
      return 2;
    } else if (path.indexOf('/product-') != -1){
      return 3;
    } else if (path.indexOf('/facilities') != -1){
      return 5;
    }
  };

  useEffect(() => {
    // window.onscroll = scroll;
  }, []);

  return (
    <div>

      {props.license ?
        <header id="header" className="fixed-top header-scrolled">
          <div className="container d-flex align-items-center">
            <img src="assets/img/lef/lef.ico" /><h1 className="logo me-auto"><a href="index.html"> LAKSAMANA ENGINEERING AND FABRICATION SDN. BHD.</a></h1>
            <nav id="navbar" className={mobile ? "navbar order-last order-lg-0 navbar-mobile" : "navbar order-last order-lg-0"}>
              <ul>
                <li><a className={(getPathId() == 1) ? "nav-link scrollto active" : "nav-link scrollto"} href="/">Home</a></li>
                <li><a className={(getPathId() == 2) ? "nav-link scrollto active" : "nav-link scrollto"} href="/about">About</a></li>
                <li className="dropdown"><a onClick={toggleDropdown.bind(null,1)} className={(getPathId() == 3) ? "active" : null} href="#"><span>Products</span> <i className="bi bi-chevron-down"></i></a>
                  <ul className={(mobile && dropdown ==1) ? "dropdown-active" : ""} >
                    <li><a href="/product-piping">Piping</a></li>
                    <li><a href="/product-skid">Skid</a></li>
                    <li><a href="/product-steel_structure">Steel Structure</a></li>
                    <li><a href="/product-pressure_vessel">Pressure Vessel</a></li>
                    <li><a href="/product-silo">Silo</a></li>
                    <li><a href="/product-heat_exchanger">Heat Exchanger</a></li>
                  </ul>
                </li>
                <li className="dropdown"><a onClick={toggleDropdown.bind(null,2)} className={(getPathId() == 4) ? "active" : null} href="#"><span>Services</span> <i className="bi bi-chevron-down"></i></a>
                  <ul className={(mobile && dropdown ==2) ? "dropdown-active" : ""} >
                    <li><a href="/service-painting_inspection">Painting Inspection</a></li>
                    <li><a href="/service-welding_work">Welding Work</a></li>
                    <li><a href="/service-blasting_painting">Blasting & Painting</a></li>
                  </ul>
                </li>
                <li><a className={(getPathId() == 5) ? "nav-link scrollto active" : "nav-link scrollto"} href="/facilities">Manufacturing Facilities</a></li>
                <li><a className={(getPathId() == 6) ? "nav-link scrollto active" : "nav-link scrollto"} href="/contact">Contact</a></li>
              </ul>
              <i className={mobile ? "bi mobile-nav-toggle bi-x" : "bi bi-list mobile-nav-toggle"} onClick={toggleMobile}></i>
            </nav>
          </div>
        </header>
        : <h1 className="license-warning">Unlicensed website</h1>
      }
      {/*<div id="topbar" className={scrolled ? "d-flex align-items-center fixed-top topbar-scrolled" : "d-flex align-items-center fixed-top"}>*/}
      {/*  <div className="container d-flex justify-content-between">*/}
      {/*    <div className="contact-info d-flex align-items-center">*/}
      {/*      <i className="bi bi-envelope"></i> <a href={"mailto:" + props.company.email}>{props.company.email}</a>*/}
      {/*      <i className="bi bi-phone"></i> {props.company.phone}*/}
      {/*    </div>*/}
      {/*    <div className="d-none d-lg-flex social-links align-items-center">*/}
      {/*      <a href={props.company.facebook} className="facebook"><i className="bi bi-facebook"></i></a>*/}
      {/*      <a href={props.company.instagram} className="instagram"><i className="bi bi-instagram"></i></a>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*<header id="header" className={scrolled ? " fixed-top header-scrolled": "fixed-top"}>*/}

    </div>
  );
}

export default withRouter(Header);
