import Header from "./components/Header";
import React, {useEffect, useState} from 'react';
import company from './data/company';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Home from "./components/Home";
import About from "./components/About";
import Facilities from "./components/Facilities";
import ProductsServices from "./components/ProductsServices";
import Contact from "./components/Contact";

function App() {
  const [licensed, setLicensed] = useState(true);
  // useEffect(() => {
  //   checkLicense().then((valid) =>{
  //     setLicensed(valid)
  //   }).catch(() => {
  //     setLicensed(true);
  //   });
  //   // window.onscroll = scroll;
  // }, []);

  return (
    <Router>
      <Header company={company} license={licensed}/>
      <Switch>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/facilities">
          <Facilities/>
        </Route>
        <Route path="/contact">
          <Contact/>
        </Route>
        <Route exact path="/product-piping">
          <ProductsServices category="product" spotlight="piping"/>
        </Route>
        <Route exact path="/product-skid">
          <ProductsServices category="product" spotlight="skid"/>
        </Route>
        <Route exact path="/product-steel_structure">
          <ProductsServices category="product" spotlight="steel_structure"/>
        </Route>
        <Route exact path="/product-heat_exchanger">
          <ProductsServices category="product" spotlight="heat_exchanger"/>
        </Route>
        <Route exact path="/product-pressure_vessel">
          <ProductsServices category="product" spotlight="pressure_vessel"/>
        </Route>
        <Route exact path="/product-silo">
          <ProductsServices category="product" spotlight="silo"/>
        </Route>

        <Route exact path="/service-pipe_coating">
          <ProductsServices category="service" spotlight="pipe_coating"/>
        </Route>
        <Route exact path="/service-painting_inspection">
          <ProductsServices category="service" spotlight="painting_inspection"/>
        </Route>
        <Route exact path="/service-welding_work">
          <ProductsServices category="service" spotlight="welding_work"/>
        </Route>
        <Route exact path="/service-blasting_painting">
          <ProductsServices category="service" spotlight="blasting_painting"/>
        </Route>

        <Route exact path="/">
          <Home company={company}/>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
