import React from 'react';

function About() {
  return (
    <div>
      <section id="about" className="about pt-100">
        <div className="container pt-5">
          <div className="row">
            <div className="justify-content-center icon-boxes">
              <h4>Introduction</h4>
              <p className="text-justify">
                <b>Laksamana Engineering and Fabrication Sdn. Bhd. (LEF)</b>, was registered on 29 April 2019 and was formerly known as <b>Laksamana Trading & Services (LTS)</b> when it was registered back then on 19 September 2012 to provide its expertise
                based on our accomplishment in the mechanical field. There is a great need to seize this opportunity and be unique in
                providing our capabilities to our valued customers and will relentlessly continue to improve and upgrade our services. In
                view of the evolving expectation in the industry we, at <b>LEF</b> will continue to strive and be committed in our strategies. We believe in establishing and providing quality services through Codes, Standards, Clients Specifications and Statutory requirements.
              </p>
            </div>
          </div>
          <div className="row pt-5">
            <div className="justify-content-center icon-boxes">
              <h4>Company Strategies</h4>
              <div className="icon-box p-2">
                <div className="icon"><i className="bx bx-navigation"></i></div>
                <h4 className="title">Purpose</h4>
                <p className="about-description">To enhance and improve our services, relationship and profitability.</p>
              </div>
              <br/>
              <div className="icon-box p-2">
                <div className="icon"><i className="bx bx-target-lock"></i></div>
                <h4 className="title">Vision</h4>
                <p className="about-description">To provide quality services in line with Codes and Standards that exceeds the expectations of our esteemed customers.</p>
              </div>
              <br/>
              <div className="icon-box p-2">
                <div className="icon"><i className="bx bx-bullseye"></i></div>
                <h4 className="title">Mission Statement</h4>
                <p className="about-description">To build a long term relationship with our customers and provide exceptional customer services by pursuing business through innovation and advanced technology.</p>
              </div>
              <br/>
              <div className="icon-box p-2">
                <div className="icon"><i className="bx bx-network-chart"></i></div>
                <h4 className="title">Core Values</h4>
                <p className="about-description">We believe in treating our customers with respect and faith. We advance through Creativity, Invention and Innovation. We integrate honesty, integrity and business ethics into all aspects of our business functioning.</p>
              </div>
              <br/>
              <div className="icon-box p-2">
                <div className="icon"><i className="bx bx-arch"></i></div>
                <h4 className="title">Goals</h4>
                <p className="about-description">Develop a strong base of key customers. Increase the assets and investments of the Company to support the development of our services. To build good reputation in the Mechanical field and become a key player in the industry.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default About;
