import React, {useState} from 'react';
import ReactBnbGallery from 'react-bnb-gallery';
import 'react-bnb-gallery/dist/style.css'
import Products from '../data/products';
import Services from '../data/services';

function ProductsServices(props) {
  const [gallery, setGallery] = useState({ open: false, index: 0});
  const toggleGallery = (index) => {
    setGallery({
      open: !gallery.open,
      index
    });
  };

  const data = props.category === "product" ? Products : Services;
  const spotlight = props.spotlight;

  return (
    <div className="minHeight600">
      <section id="departments" className="departments">
        <div className="container pt-5 pb-5">

          <div className="section-title">
          </div>

          <div className="row">
            {/*<button onClick={toggleGallery}>Open photo gallery</button>*/}
            <ReactBnbGallery activePhotoIndex={gallery.index} show={gallery.open} photos={data[spotlight].photos} onClose={toggleGallery.bind(null, 0)} />
            <div className="col-lg-3">
              <ul className="nav nav-tabs flex-column">
                {
                  Object.keys(data).map((e, i) => {
                    return <li className="nav-item" key={i}>
                      <a className={ e === spotlight ? "nav-link active show" : "nav-link" }
                         href={"/" + props.category + "-" + e}>{data[e].title}</a>
                    </li>
                  })
                }
              </ul>
            </div>
            <div className="col-lg-9 mt-4 mt-lg-0">
              <div className="tab-content">
                <div className="tab-pane active show" id="tab-1">
                  <div className="row">
                    <div>
                      <h3>{data[spotlight].title}</h3>
                      <p className="fst-italic">{data[spotlight].description}</p>

                      <div>
                        {data[spotlight].photos.map((e, i) =>{
                          return <img key={i} src={data[spotlight].photos[i].thumbnail} alt="" className="thumbnail-image" onClick={toggleGallery.bind(null, i)} />
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
    </div>
  )
}

export default ProductsServices;
